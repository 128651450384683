// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: false,
  version: env['npm_package_version'] + '-dev',
  defaultLanguage: 'en',
  versionLanguageEn: 'https://dev-en.el-pixel.com',
  versionLanguageRu: 'https://dev-ru.el-pixel.by',
  versionLanguagePl: 'https://dev-pl.el-pixel.pl',
  supportedLanguages: ['en'],
  mailServiceUrl: 'https://static.el-pixel.com/mail/send_email/',
  staticUrl: 'https://static2.el-pixel.com',
  pixelUrl: 'https://be.el-pixel.com/graphql',
  ga: 'G-4FDNQMV68X',
  firebase: {
    apiKey: 'AIzaSyCpaYvkzuUA55oQ5u69L1kfhLf_LQYgAV8',
    authDomain: 'el-pixel.firebaseapp.com',
    databaseURL:
      'https://el-pixel-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'el-pixel-website-dev',
    storageBucket: 'el-pixel-website-dev.appspot.com',
    messagingSenderId: '747287962243',
    appId: '1:747287962243:web:5df60577e4c34888fc31b6',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
